import styled, { ThemeContext } from 'styled-components/macro'
import TradingViewWidget from 'react-tradingview-widget'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'

function Multichart() {
  const [symbols] = useState([
    'KIBAUSD_6499B4',
    'WETHKIBA',
    'BINANCE:BTCUSDT',
    'BINANCE:ETHUSDT',
    'BINANCE:BNBUSDT',
    'BINANCE:MATICUSDT',
    'ETHUSD',
    'BTCUSD',
    'BNBUSD',
  ])
  const darkMode = useIsDarkMode()
  const [nchart, setNchart] = useState(9)

  console.log(symbols)
  console.log(nchart)
  return (
    <div>
      <Nav>
        <Menu>
          <span onClick={() => setNchart(2)}> 1 x 2</span>
          <span onClick={() => setNchart(4)}> 2 x 2</span>
          <span onClick={() => setNchart(6)}> 2 x 3</span>
          <span onClick={() => setNchart(9)}> 3 x 3</span>
        </Menu>
      </Nav>
      <Main nchart={nchart}>
        {symbols
          .filter((items, idx) => idx < nchart)
          .map((symbol, index) => (
            <Charts key={index}>
              <TradingViewWidget
                symbol={symbol}
                theme={darkMode ? 'dark' : 'light'}
                autosize="true"
                interval="60"
                timezone="USA/Eastern"
                style="3"
                locale="en"
                toolbar_bg={darkMode ? '#ffffff' : '#000000'}
                hide_side_toolbar="false"
                allow_symbol_change="true"
              />
            </Charts>
          ))}
      </Main>
    </div>
  )
}

export default Multichart

const Main = styled.div`
  margin-top: -50px;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  display: grid;
  grid-template-columns: ${({ nchart }) => {
    if (nchart === 2) return 'repeat(2, minmax(0, 1fr))'
    if (nchart === 4) return 'repeat(2, minmax(0, 1fr))'
    if (nchart === 6) return 'repeat(3, minmax(0, 1fr))'
    if (nchart === 9) return 'repeat(3, minmax(0, 1fr))'
  }};
  min-height: 82vh;
  min-width: 100vw;
  height: 100%;
`
const Charts = styled.div`
  border: 1px solid black;
  :hover {
    border: 2px solid #f76c1d;
  }
`
const Nav = styled.div`
  height: 30px;
  color: ${({ theme }) => theme.text1};
  z-index: 3;
  display: flex;
  align-items: center;
  //justify-content: space-between;
`
const Menu = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  color: ${({ theme }) => theme.text1};

  span {
    border: 2px solid ${({ theme }) => theme.text1};
    border-radius: 8px;
    padding: 2px 4px;
    transition: all 0.15s ease-in;
    margin-right: 20px;
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.text1};
      background: ${({ theme }) => theme.text2};
    }
  }
`
