import { SupportedChainId } from './chains'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'

type AddressMap = { [chainId: number]: string }

export const OG_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x25553828f22bdd19a20e4f12f052903cb474a335',
  [SupportedChainId.BINANCE]: '0xacb85595c219e7fc92623a0934ed72d7968d9bf4',
}

export const QJBA_RELAYER: AddressMap = constructSameAddressMap('0x2Ec705D306b51e486B1bC0D6ebEE708E0661ADd1', [
  SupportedChainId.MAINNET,
  SupportedChainId.BINANCE,
])

export const OG2_RELAYER: AddressMap = constructSameAddressMap('0x5b599155aDE1F59f549a4a1297ddb5A4951b1B27', [
  SupportedChainId.MAINNET,
  SupportedChainId.BINANCE,
])

export const MA_RELAYER: AddressMap = constructSameAddressMap('0x6A8E20C1580c85A98608107A42ABb5814d8Cf3a5', [
  SupportedChainId.MAINNET,
  SupportedChainId.BINANCE,
])

export const MAGO_RELAYER: AddressMap = constructSameAddressMap('0xBcfA32CF40d0139084e42daAf00bF914e221fD7e', [
  SupportedChainId.MAINNET,
  SupportedChainId.BINANCE,
])

export const INDY_DEV: AddressMap = constructSameAddressMap('0x5b599155aDE1F59f549a4a1297ddb5A4951b1B27', [
  SupportedChainId.MAINNET,
  SupportedChainId.BINANCE,
])




