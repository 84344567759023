import React from 'react';

const KibaBridge: React.FC = () => {
  return (
      <div style={{ marginTop: '-60px', height: '88vh', width: '100vw' }}>
        <iframe style={{ height: '100%', width: '100%' }}src={'https://kiba-inu-bridgev2.netlify.app/'} />
      </div>
  );
};

export default KibaBridge;