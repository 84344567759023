import * as styles from './MenuDropdown.css'
import {
  SwapIcon,
} from 'components/AndyComponents/icons'
import {
  ChevronDown,
  ChevronUp,
  Repeat,
  Minimize2,
} from 'react-feather'
import { Column, FlRow } from 'components/AndyComponents/Flex'
import { MenuHoverA, MenuHoverB } from './MenuHover'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import { ReactNode, useContext, useReducer, useRef } from 'react'
import { body } from 'components/AndyComponents/common.css'
import styled, { ThemeContext } from 'styled-components/macro'
import { useIsMobileSp } from 'components/AndyComponents/AndyHooks'
import { Box } from 'components/AndyComponents/Box'
import { NavDropdown } from './NavDropdown'
import { Trans } from '@lingui/macro'

import { useOnClickOutside } from 'hooks/useOnClickOutside'



const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps['to']
  href?: string
  close?: () => void
  children: ReactNode
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to} className={styles.MenuRow}>
          <FlRow onClick={close}>{children}</FlRow>
        </NavLink>
      ) : (
        <FlRow as="a" href={href} target={'_blank'} rel={'noopener noreferrer'} className={styles.MenuRow}>
          {children}
        </FlRow>
      )}
    </>
  )
}


const PrimaryMenuRowText = ({ children }: { children: ReactNode }) => {
  return <Box className={`${styles.PrimaryText} ${body}`}>{children}</Box>
}

PrimaryMenuRow.Text = PrimaryMenuRowText

const ModalMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0rem 0rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  :hover {
    color: ${({ theme }) => theme.text2};
    transition: ease all 0.2s ;
    cursor: pointer;
    text-decoration: none;
  }
`


const ChevronWrapper = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0px 0px 0px 0px;

  :hover {  
  }
  :hover,
  :active,
  :focus {
    border: none;
  }
`

interface MenuItemProps {
  isActive?: boolean
  children: ReactNode
}

const ToolsWrapper = ({ isActive, children }: MenuItemProps) => {
  return (
    <Box className={isActive ? styles.activeMenuItem : styles.menuItem}>
      {children}
    </Box>
  )
}
const MenuDivider = styled.div`
 
  margin: 0px;
  width: 2px;
`



const Icon = ({ href, children }: { href?: string; children: ReactNode }) => {
  return (
    <>
      <Box
        as={href ? 'a' : 'div'}
        href={href ?? undefined}
        target={href ? '_blank' : undefined}
        rel={href ? 'noopener noreferrer' : undefined}
        display="flex"
        flexDirection="column"
        color="textPrimary"
        background="none"
        border="none"
        justifyContent="center"
        textAlign="center"
        marginRight="12"
      >
        {children}
      </Box>
    </>
  )
}

export const SwapDropdown = () => {
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)
  const isMobile = useIsMobileSp()
  const theme = useContext(ThemeContext)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)
  const { pathname } = useLocation()

  return (
    <>
      
      <Box position="relative" ref={ref} >

      {isMobile? <MenuHoverB isActive={pathname.startsWith('/swap')} onClick={toggleOpen}>
          <ToolsWrapper isActive={pathname.startsWith('/swap')}>
            <SwapIcon/>
          </ToolsWrapper>
          </MenuHoverB>  
          : null
        }
      
  
        {isMobile? null : <MenuHoverA isActive={pathname.startsWith('/swap')} onClick={toggleOpen}>
           <FlRow><Trans>Swap </Trans><ChevronWrapper>
            <MenuDivider/>
            {isOpen ? <ChevronUp size={24} color={theme.text2}  /> : <ChevronDown size={20} color={theme.text2} />}
          </ChevronWrapper></FlRow>
        </MenuHoverA>}

        {isOpen && (
          <NavDropdown top={{ sm: 'unset', lg: '56' }} bottom={{ sm: '56', lg: 'unset' }} left="0" onMouseLeave={toggleOpen}>
            <Column gap="16">
              <Column paddingX="8" gap="4">
                <PrimaryMenuRow to="/swap" close={toggleOpen}>
                  <Icon>
                  <Repeat opacity={0.6} size={16} />
                  </Icon>
                  <ModalMenuItem>
                    <Trans>Swap</Trans>
                  </ModalMenuItem>
                </PrimaryMenuRow>
                <PrimaryMenuRow to="/swap/limit" close={toggleOpen}>
                  <Icon>
                  <Minimize2 opacity={0.6} size={16} />
                  </Icon>
                  <ModalMenuItem>
                    Limit Order
                  </ModalMenuItem>
                </PrimaryMenuRow>
              </Column>
              
              
            </Column>
          </NavDropdown>
        )}
      </Box>
     
    
    </>
  )
}
